import React, { useEffect } from "react"

//  store
import team from "../store/team"
import { aboutUsOriginsOne, aboutUsOriginsTwo } from "../store/images"

// components
import Layout from "../components/layout"
import ShadowBox from "../components/shadow-box"
import { scrollAnimationAboutUs } from "../components/scroll-animation"

const AboutUs = () => {
  useEffect(() => {
    scrollAnimationAboutUs()
  }, [])
  return (
    <Layout title="About Us" description="Add SEO desc here - about us">
      {/* hero */}
      <section
        style={{
          backgroundImage: "url(/assets/images/hero-lady.jpg)",
          backgroundSize: "50% auto",
        }}
        className="site-container pt-32 pb-48 sm:pb-0 sm:h-screen bg-right-bottom bg-no-repeat lg:flex items-center"
      >
        <div className="space-y-8 max-w-xl xl:max-w-2xl 2xl:max-w-3xl">
          <h1 className="type-8xl text-blue-800 font-semibold leading-10 sm:leading-normal">
            Our mission is to help small businesses access fair and affordable
            finance.
          </h1>
          <h3 className="type-5xl font-light text-blue-300">
            We help SMEs of all sizes gain access to affordable working capital
            solutions.
          </h3>
          <p className="type-base text-grey-500">
            Clear Factor was founded on the principle that all businesses should
            have equal access to affordable working capital solutions. Clear
            Factor is the UK’s first fully digital invoice finance marketplace
            where trade investors compete to buy SME invoices in a transparent,
            borderless and democratised ecosystem. Introducing SMEs to an
            affordable alternative to traditional lending, while creating an
            alternative asset class for funders.
          </p>
        </div>
      </section>
      {/* origins */}
      <section className="site-container py-20 bg-blue-200 space-y-14">
        <ShadowBox className="p-8 space-y-10 lg:flex items-center max-w-8xl mx-auto slide-in--1">
          <div className="space-y-6 lg:w-1/2 lg:pl-16">
            <h2 className="type-8xl text-blue-800 font-semibold">
              Clear Factor's Origins
            </h2>
            <p className="type-base text-grey-500">
              The idea came about after the financial crisis in 2008, where one
              of our founders had found himself in the thick of it all. In 2002
              our co-founder, Ricky Shankar had put up his home as collateral
              while launching his then software services company. Despite it
              being profitable at the time, cash flow was becoming an issue and
              he required access to working capital to pay the monthly bills.
              Ricky investigated into invoice financing and within a few weeks
              agreed on an invoice finance facility allowing his company to draw
              down 85% of all invoices raised immediately, therefore solving all
              their cashflow problems. In the aftermath of the crash, his
              company was classified as ‘high risk’ despite it being a reliable
              client and was therefore given six months to find a new invoice
              finance lender. This is how it all started.
            </p>
          </div>
          <div className="lg:w-1/2 lg:order-first">
            <div className="lg:-mt-10">{aboutUsOriginsOne}</div>
          </div>
        </ShadowBox>
        <ShadowBox className="p-8 space-y-10 lg:flex items-center max-w-8xl mx-auto slide-in--6">
          <div className="space-y-6 lg:w-1/2 lg:pl-16">
            <p className="type-base text-grey-500">
              As a direct result of the financial crisis and having lived
              through the crash, in addition to receiving a poor experience of
              the existing processes regarding traditional invoice financing,
              Ricky had come up with the initiative to digitally reinvent the
              invoice financing sector once and for all.  This idea then came to
              life when a team of technologically and strategically innovative
              financial professionals joined forces, along with the right
              digital technology and industry experience; Clear Factor was born.
            </p>
          </div>
          <div className="lg:w-1/2">
            <div className="lg:-mt-10">{aboutUsOriginsTwo}</div>
          </div>
        </ShadowBox>
      </section>
      {/* our team, removed 1st July 2024 */}
    </Layout>
  )
}

export default AboutUs
